/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

* {
  font-family: "Nunito Sans", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Roboto", sans-serif !important; */
  font-family: "Nunito Sans", sans-serif;
}

.divider__block,
.text-title {
  /* font-family: "Roboto", sans-serif !important; */
  font-family: "Nunito Sans", sans-serif;
  font-weight: 900;
}

.navbar-brand {
  /* width: 431.71px; */
  /* margin-right: 10rem; */
  margin: 0;
  padding: 0;
  height: 100%;
}

.navbar-top {
  left: 0px;
  top: 0px;
  border-radius: 0px;
  padding: 6px 12px;
  font-size: 90%;
  border-width: 0px !important;
  font-family: "Roboto", sans-serif !important;
}

.navbar-nav {
  justify-items: space-between;
}

.nav-item {
  display: flex;
  align-items: center;
}

.nav-item:hover {
  background-color: #87111f;
}
.nav-link {
  color: white !important;
}

.nav-link {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 90%;
  letter-spacing: 0.005em;
  position: relative;
}
.nav-link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-link:hover:after {
  width: 100%;
  left: 0;
}

.top-icon {
  color: #a4aaaf;
  transition: color 300ms ease-in-out;
}

.top-icon:hover {
  color: white;
}

.text-logo-title {
  font-size: 0.65rem;
  letter-spacing: 0.05rem;
}
.text-logo-main {
  font-size: 0.8rem;
  letter-spacing: 0.07rem;
}

.nav-img {
  height: 10vh;
}

.navbar-logo {
  width: 100%;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}
.nav-top-wrapper {
  border-width: 0px !important;
}

.carousel-image {
  height: 30vh !important;
  object-fit: cover;
}

.image-slider-item {
  background-color: #505050 !important;
}

.image-slider-item img {
  mix-blend-mode: overlay;
}

.carousel-indicators button {
  height: 12.5px !important;
  width: 12.5px !important;
  background: #fefeff;
  /* Primary / 500 */
  border: 1.25px solid #ca192f !important;
  border-radius: 50% !important;
  transition: all 0.5s ease-in-out !important;
}

.carousel-indicators .active {
  height: 12.5px !important;
  width: 17.5px !important;
  border: 1.25px solid #ffffff !important;
  background: #fefeff !important;
  border-radius: 10px !important;
}

/* 
.navbar-toggler-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="rgb(211, 211, 211)" viewBox="0 0 24 24" stroke="rgb(211, 211, 211)"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" /></svg>') !important;
  color: rgb(211, 211, 211);
}

/* .gallery-wrapper {
  overflow: hidden;
  height: 30rem;
} */

/* .gallery-wrapper div {
  height: 50%;
  overflow: hidden;
} */

.gallery-wrapper div img {
  height: 100%;
  object-fit: cover;
}

html,
body,
* {
  scroll-behavior: smooth;
}

.divider {
  position: relative;
  display: block;
  line-height: 100%;
  width: 100%;
}
.divider:before {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #ca192f;
  display: block;
  position: absolute;
  top: 50%;
  z-index: -1;
}

.divider__group {
  display: inline-block;
  vertical-align: sub;
}
.divider__group--right {
  float: right;
}

.divider--right {
  text-align: right;
}
.divider--right .divider__block {
  padding-left: 10px;
}

.divider--center {
  text-align: center;
}
.divider--center .divider__block {
  padding-right: 10px;
  padding-left: 10px;
}

.divider--left {
  text-align: left;
}
.divider--left .divider__block {
  padding-right: 10px;
}

.divider__block {
  background-color: #fff;
  display: inline-block;
  z-index: 2;
}

.text-title {
  font-style: normal;
  font-weight: 900;
  font-size: 100%;
  line-height: 140%;
  letter-spacing: -0.005em;
}

.gallery-wrapper {
  padding: 0;
}

.index-max div {
  z-index: 999999;
}

.img-blend {
  position: relative;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

.team-img {
  height: 12rem;
  width: 12rem;
  object-fit: cover;
}

/* .img-blend::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ca192f;
  opacity: 0.2;
} */
.blend-darken {
  background-color: #505050 !important;
}

.blend-darken img {
  mix-blend-mode: overlay;
}
*/ .img-blend img {
  width: 100%;
}

/* .img-blend .content svg {
  right: 1.4rem;
  bottom: -0.3rem;
} */

.content {
  height: 35% !important;
}

.content h3 {
  color: white;
  width: 100%;
  font-weight: 700;
  font-weight: bold;
}

.more-btn {
  width: 65%;
}

.gallery-img {
  height: 18rem !important;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(5, 1fr); */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.top-btn {
  margin-bottom: 5rem;
}

.sponsor-container {
  padding-top: 2rem;
}

.sposnor-img {
  width: fit-content;
}

.bottom-logo-container {
  top: -5rem;
}

.bottom-logo {
  width: 10rem;
}

.dropdown-custom {
  border: 1px solid #002f6c;
  box-shadow: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-custom-menu li span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.dropdown-custom-menu {
  padding: 0;
}

.dropdown-custom:focus {
  outline: none;
  box-shadow: none;
}

.dropdown-custom-menu {
  border-radius: 0;
  width: 100%;
}

.custom-form-control,
.country-select__form-control {
  border-radius: 0;
  border: 1px solid #002f6c;
  box-sizing: border-box;
}

.custom-form-control:focus,
.country-select__form-control:focus {
  box-shadow: none;
  border: 2px solid #002f6c;
}

.neutral {
  color: #313940 !important;
}

.cursor-pointer {
  cursor: pointer;
}
.counter div {
  text-align: center;
  font-size: 1rem;
}

.fixture-container img {
  width: 10rem !important;
}

/* 
.carousel-index {
  z-index: 0;
} */
/* .gallery-wrapper div:nth-child(1) div .img-container {
  height: 15rem;
}
.gallery-wrapper div:nth-child(1) div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
} */

/* 
.carousel-indicators button {
  height: 10px !important;
  width: 10px !important;
  background-color: white !important;
  border-radius: 100%;
  display: inline-block;
}

.carousel-indicators .active {
  height: 10px !important;
  width: 17px !important;
  border-radius: 20px !important;
  /* border-color: #ca192f !important;
  border-width: 2px;
  background-color: white !important;
  display: inline-block;
} 

*/

/* .carousel-control-next-icon {
  background-color: white;
  color: red;
} */
/* 
.carousel-control-icon-wrapper {
  padding: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
}

.carousel-control-icon-wrapper:hover {
  background-color: white;
  border-radius: 50%;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  border-radius: 50%;
  border: 1px solid white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */
.image-resize1 {
  height: 12rem;
  object-fit: cover;
  object-position: center center;
}
.image-resize {
  width: 100%;
  height: 22rem;
  object-fit: cover;
  object-position: center center;
}

.navbar-fix {
  height: 4rem;
}

.navbar-logo {
  top: -0.2rem;
  left: 0;
  height: 110px !important;
  width: 115px !important;
}

.head-icon {
  height: 26px !important;
  width: 26px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.partner-img {
  width: 4rem !important;
}

.vertical-divider {
  width: 28px;
  height: 0.15rem;
  background-color: #ca192f;
  transform: rotate(-90deg);
}
/* .navbar-logo {
  background-color: #ca192f;
} */

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

/* .gallery-container {
  padding: 0 5rem !important;
} */

/* .team-profile-wrapper {
  height: 350px !important;
} */

.team-profile-wrapper {
  height: 100% !important;
  width: 400px;
}
.team-content {
  height: 350px;
  width: 100%;
}

.team-profile-img {
  border-radius: 0;
  height: 100% !important;
  width: 100% !important;
}

.arrow-right::after {
  right: 5px;
}

.team-jersy {
  z-index: 99;
}
.jersey-height {
  height: 29px;
}

.arrow-right::after {
  z-index: -1;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #ca192f;
  position: absolute;
  margin-top: -15px;
  top: 50%;
  transition: all 0.4s ease-in-out;
}

.overlay-team-profile {
  bottom: 0;
  height: 0%;
  transition: all 0.4s ease-in-out;
  background-color: #ca192e96;
}

.team-profile-extra {
  transition-delay: 0.2s;
  transition: all 0.4 ease-in-out;
}

.team-profile-wrapper:hover .arrow-right::after {
  right: -15px;
}
.team-profile-extra {
  opacity: 0;
}

.team-profile-wrapper:hover .team-profile-extra {
  opacity: 1;
}

.team-profile-wrapper:hover .overlay-team-profile {
  height: 100%;
}

.neutral {
  color: #181c20;
}

.neutral-bg {
  color: #181c20;
}

.footer-bg {
  background: #ebebeb;
}

.footer-divider {
  height: 2px;
}

.news-wrapper-media {
  background-color: #ca192e5d;
}

.news-img-media {
  height: 128px !important;
  object-fit: cover;
}

.nes-details-medial {
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 24px;
}

.view-more {
  line-height: 140%;
  letter-spacing: 0.005em;
  text-decoration-line: underline;
  line-height: 140%;
}

.table-img {
  width: 32px;
  height: 32px;
}

.clear-table {
  width: 100%;
}

.text-size1 {
  font-size: 14px;
  font-weight: 600;
}
.text-size2 {
  font-size: 16px;
  font-weight: 400px;
}
.text-wrap {
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-wrap {
  height: 272px;
}

.pagination {
  color: rgba(124, 0, 0, 0.712);
}

.view-btn:hover {
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.1s ease;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 2;
}
.main-image-resize {
  width: 100%;
  height: 190px;
  object-fit: cover;
  object-position: center center;
}
.image-resize1 {
  height: 192px;
  object-fit: cover;
  object-position: center center;
}

.fix-banner-wrapper {
  height: 436px;
  overflow: hidden;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
}

.carousel-control-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  padding: 0.4rem !important;
  border-radius: 50%;
  background-color: transparent;
  opacity: 1 !important;
  transition: all 0.2s ease-in-out !important;
}

.next-icon:hover .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.prev-icon:hover .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") !important;
}

.carousel-control-icon-wrapper:hover {
  background-color: white;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  opacity: 1 !important;
  transition: all 0.2s ease-in-out !important;
}
.scale-anim {
  overflow: hidden;
  cursor: pointer;
}

.scale-anim img {
  transition: transform 0.4s ease-in-out;
}

.scale-anim:hover img {
  transform: scale(1.1);
  cursor: pointer;
}

.sponsor-title {
  width: 11rem;
}
.image-free-position {
  width: 100%;
  height: 90%;
}
.matches-scroll {
  width: 100%;
  height: 425px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px;
}
.small-text {
  font-size: 10px;
}
.team-banner-image {
  width: 100%;
  height: 190px;
  object-fit: cover;
  object-position: center center;
}

.gallary-image {
  height: 289px;
  object-fit: cover;
  object-position: center center;
}
.gallary-adv-image {
  height: 120px;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}
.gallery-text-wrap {
  height: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gallary-date-size {
  font-size: 12px;
  font-weight: bold;
  color: gray;
}
.gallary-text-size {
  font-size: 24px;
  font-weight: 700px;
}
.dummy-image {
  height: 120px;
  width: 100%;
}
.logo {
  height: 50px;
}
.lastmatch-image {
  height: 70px;
}
.comming-soon {
  width: 100%;
  height: 165px;
  object-fit: cover;
  object-position: center center;
}
.latest-news {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.425);
  font-weight: bold;
  color: #000000;
}
.news-para {
  overflow: hidden;
  height: 120px;
}
.news-text-overlay {
  background-color: rgb(202, 25, 47);
}
.foot {
  background-color: rgb(208, 33, 13);
}
.footer-image {
  width: 100%;
  height: 150px;
}
.contact-image {
  width: 40%;
}
.contact-form-fill {
  width: 85% !important;
  margin-left: 41px;
}
.contact-football-image {
  width: 100%;
  height: 320px;
}
.contact-background-color {
  background-color: #f6f6f6;
}

.club-name-text {
  font-size: 12px;
}
.overlay-darken {
  background-color: #000000be !important;
}

.overlay-darken img {
  mix-blend-mode: overlay;
}
.overlay-darken {
  position: relative;
}

.image-count-overlay {
  position: absolute;
  top: 32%;
  left: 45%;
}
.hover-zoomin span {
  display: block;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 600px) {
  .carousel-image {
    height: 45vh !important;
  }
  .gallery-img {
    height: 22rem !important;
  }
  .team-img {
    height: 15rem;
    width: 15rem;
  }
  .bottom-logo-container {
    top: -4rem;
  }

  .image-resize {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    object-position: center center;
  }
  .news-wrap:hover {
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 2;
  }
  .news-para {
    overflow: hidden;
    height: 120px;
  }
  .team-profile-wrapper {
    height: 100% !important;
    width: 300px;
  }
  .main-image-resize {
    height: 190px;
    object-fit: cover;
    object-position: center center;
  }

  .image-resize1 {
    height: 192px;
  }
  .matches-scroll {
    width: 100%;
    height: 425px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px;
  }
  .lastmatch-image {
    height: 100px;
  }
  .dummy-image {
    height: 100px;
  }
  .small-text {
    font-size: 16px;
    font-weight: bold;
  }
  .team-banner-image {
    height: 440px;
    object-fit: cover;
  }
  /* .main-image-resize {
    height: 27.188rem;
    object-fit: cover;
    object-position: center center;
  } */
  .gallary-adv-image {
    height: 140px;
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }
  .gallary-image {
    height: 289px;
    object-fit: cover;
    object-position: center center;
  }
  .gallery-text-wrap {
    height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .gallary-date-size {
    font-size: 12px;
    font-weight: bold;
    color: gray;
  }
  .gallary-text-size {
    font-size: 24px;
    font-weight: 700px;
  }
  .footer-image {
    width: 50%;
    height: 150px;
  }
  .contact-form-fill {
    width: 85% !important;
    margin-left: 11px;
  }
  .overlay-darken {
    background-color: #000000be !important;
  }

  .overlay-darken img {
    mix-blend-mode: overlay;
  }
  .overlay-darken {
    position: relative;
  }

  .image-count-overlay {
    position: absolute;
    top: 32%;
    left: 45%;
  }
  .hover-zoomin span {
    display: block;
    position: relative;
    overflow: hidden;
  }
  .team-name {
    height: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .navbar-logo {
    top: -0.2rem;
    left: 0;
    height: 120px !important;
    width: 135px !important;
  }
  .carousel-image {
    height: 40vh !important;
  }
  /* .gallery-wrapper {
    height: 30rem;
  } */
  .more-btn {
    width: 50%;
  }
  .gallery-img {
    height: 18rem !important;
  }
  .team-img {
    height: 12rem;
    width: 12rem;
  }
  .bottom-logo-container {
    top: -5rem;
  }
  .image-resize {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    object-position: center center;
  }

  .image-resize1 {
    height: 192px;
  }

  .team-profile-img {
    border-radius: 0;
    /* height: 320px !important; */
    height: 100% !important;
    width: 100% !important;
  }
  .matches-scroll {
    width: 100%;
    height: 425px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px;
  }
  .lastmatch-image {
    height: 170px;
  }
  .dummy-image {
    height: 235px;
  }
  .team-banner-image {
    height: 440px;
    object-fit: cover;
  }
  .main-image-resize {
    height: 27.188rem;
    object-fit: cover;
    object-position: center center;
  }
  .gallary-image {
    height: 289px;
    object-fit: cover;
    object-position: center center;
  }
  .gallary-adv-image {
    height: 140px;
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }
  .gallery-text-wrap {
    height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-wrap:hover {
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 2;
  }

  .row-relative {
    position: relative;
  }

  .team-profile-img {
    border-radius: 0;
    /* height: 320px !important; */
    height: 100% !important;
    width: 100%;
  }
  .team-profile-wrapper {
    margin: 0 13px;
  }
  .gallary-date-size {
    font-size: 12px;
    font-weight: bold;
    color: gray;
  }
  .gallary-text-size {
    font-size: 24px;
    font-weight: 700px;
  }
  .comming-soon {
    width: 80%;
    height: 270px;
    object-fit: cover;
    object-position: center center;
  }
  .advertisment-container {
    height: 235px;
  }
  .news-para {
    overflow: hidden;
    height: 120px;
  }
  .contact-form-fill {
    width: 75% !important;
    margin-left: 96px;
  }
  .overlay-darken {
    background-color: #000000be !important;
  }

  .overlay-darken img {
    mix-blend-mode: overlay;
  }
  .overlay-darken {
    position: relative;
  }

  .image-count-overlay {
    position: absolute;
    top: 32%;
    left: 45%;
  }
  .hover-zoomin span {
    display: block;
    position: relative;
    overflow: hidden;
  }
  .team-name {
    height: 20px;
  }
  .team-content {
    height: 300px;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-logo {
    background-color: #ca192e00 !important;
  }
  .navbar-logo {
    height: 132px !important;
    width: 141px !important;
  }
  .navbar-logo {
    width: auto;
    top: -0.8rem;
  }

  .navbar-top {
    border-width: 2px !important;
  }
  /* .nav-top-wrapper {
    border-width: 2px !important;
  } */
  .carousel-image {
    height: 431px !important;
  }
  /* .gallery-wrapper {
    height: 40rem;
  } */

  .main-image-resize {
    height: 436px;
    object-fit: cover;
    object-position: center center;
  }

  .image-resize1 {
    height: 192px;
    object-fit: cover;
    object-position: center center;
  }
  .image-resize {
    width: 100%;
    height: 13rem;
  }
  .gallary-image {
    height: 289px;
    object-fit: cover;
    object-position: center center;
  }
  .hover-mask div {
    display: block;
    position: relative;
    overflow: hidden;
  }
  .hover-mask img {
    width: 100%;
    height: auto;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .hover-mask:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  .hover-mask div h2 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;
    width: 100%;
    padding: 45% 20px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
  .hover-mask:hover div h2 {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
  }
  .hover-mask .glyphicon {
    position: absolute;
    top: 104px;
    right: 115px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    color: #fff;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
  .hover-mask:hover .glyphicon {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=1)";
    filter: alpha(opacity=1);
    opacity: 1;
  }
  .gallary-adv-image {
    height: 140px;
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }
  .gallery-text-wrap {
    height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .gallary-date-size {
    font-size: 12px;
    font-weight: bold;
    color: gray;
  }
  .gallary-text-size {
    font-size: 24px;
    font-weight: 700px;
  }
  .overlay-darken {
    background-color: #000000be !important;
  }

  .overlay-darken img {
    mix-blend-mode: overlay;
  }
  .overlay-darken {
    position: relative;
  }

  .image-count-overlay {
    position: absolute;
    top: 32%;
    left: 45%;
  }
  .slick-arrow {
    background-color: #ca192f;
    width: 43px;
    height: 50px;
    top: 155px;
  }
  .slick-disabled {
    opacity: 100;
    color: rgb(255, 255, 255);
  }
  .slick-prev:before {
    content: "\276E";
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 0.5;
    opacity: 100;
  }
  .slick-prev:hover,
  .slick-next:hover {
    background-color: rgb(160, 5, 5);
  }
  .slick-next:before {
    content: "\276F";
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 0.5;
    opacity: 100;
  }

  .bottom-align-text {
    position: absolute;
    bottom: 4%;
    right: 2%;
    text-decoration: underline;
  }
  .image-free-position {
    height: 90%;
    width: 98%;
    padding-left: 13px;
  }
  .news-wrap:hover {
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 2;
  }

  .latest-news {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.425);
    font-weight: bold;
    color: #000000;
  }
  .boardmember-text {
    font-size: 18px;
  }
  .hover-zoomin span {
    display: block;
    position: relative;
    overflow: hidden;
  }
  .hover-zoomin img {
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .hover-zoomin:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }

  .hover-fade div {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #333;
  }
  .hover-fade img {
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .hover-fade:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0.5)";
    filter: alpha(opacity=0.5);
    opacity: 0.5;
  }

  .gallery-img {
    height: 22rem !important;
  }
  .more-btn {
    width: 30%;
  }
  .team-img {
    height: 15rem;
    width: 15rem;
  }

  .navbar-nav {
    padding: 0 12rem !important;
  }

  .team-profile-img {
    border-radius: 0;
    /* height: 360px !important; */
    height: 100% !important;
    width: 100%;
  }
  .sponsor-clearfix {
    width: 55rem;
  }
  .advertisment-container {
    height: 436px;
  }
  .matches-scroll {
    width: 100%;
    height: 425px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px;
  }
  .league-relative {
    position: relative;
  }
  .advertisment-image {
    position: absolute;
    top: 537px;
    height: 1041px;
    width: 17.5%;
  }
  .lastmatch-image {
    height: 140px;
  }
  .dummy-image {
    height: 220px;
  }
  .team-banner-image {
    height: 440px;
    object-fit: cover;
  }
  .comming-soon {
    width: 60%;
    height: 219px;
    object-fit: cover;
    object-position: center center;
  }
  .grid-image {
    height: 272px;
    object-fit: cover;
    object-position: center center;
  }
  .ribbon-box {
    position: absolute;
    border-top: 40px solid #ca192f;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    transform: rotate(90deg);
    left: 72.7%;
    top: 10%;
    z-index: 2;
  }
  .news-text-overlay {
    position: absolute;
    bottom: 0%;
    width: 66%;
    background-color: rgba(20, 20, 20, 0.452);
  }
  .news-para {
    overflow: hidden;
    height: 172px;
  }
  .contact-image {
    width: 50%;
  }
  .contact-football-image {
    width: 60%;
    height: 436px;
  }

  .remarks-boxsize {
    height: 120px;
  }
  .contact-form-fill {
    margin-left: 35%;
    width: 60% !important;
  }
  .club-name-text {
    font-size: 16px;
  }
  .team-name {
    height: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .clear-table {
    width: auto;
  }
  .news-wrap:hover {
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 2;
  }
  .comming-soon {
    width: 70%;
    height: 270px;
    object-fit: cover;
    object-position: center center;
  }
  .team-profile-wrapper {
    margin: 0 13px;
  }
}
